<template>
  <div>
    <quill-editor v-model="richText" ref="myQuillEditor" :options="editorOption" style="height:200px;"
      @focus="focus($event)">
    </quill-editor>
    <el-upload v-show="false" class="avatar-uploader" action="/api/sys/attachment/upload" accept="image/*"
      :show-file-list="false" :headers="headers" :on-success="uploadSuccess" :on-error="uploadError">
      <el-button ref="quillUpload" slot="trigger" icon="el-icon-upload" size="small" type="primary"></el-button>
    </el-upload>
  </div>
</template>

<script>
import Quill from "quill";
import { quillEditor } from "vue-quill-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { getToken } from "@/util/auth";
export default {
  name: "ArtisanRichText",
  props: ["value", "placeholder", "isEdit"],
  components: {
    quillEditor,
  },
  data() {
    return {
      headers: {
        AppAuth: "dGVzdHw2MGNiMTQxMmQwZDc5Mjg0MGE2ODQ5N2UwYzU5MGViZQ==",
        Authorization: "",
      },
      richText: this.value,
      editorOption: {
        theme: "snow",
        placeholder: this.placeholder,
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
          //["link", "image", "video"],
          imageDrop: true,
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      },
    };
  },
  watch: {
    value() {
      this.richText = this.value;
    },
    richText() {
      this.$emit("input", this.richText);
    },
  },
  created() {
    const token = getToken();
    this.headers.Authorization = "bearer " + token;
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.myQuillEditor.quill
        .getModule("toolbar")
        .addHandler("image", () => {
          this.$refs.quillUpload.$el.click();
        });
    });
  },
  methods: {
    focus(event) {
      if (this.isEdit) {
        event.enable(true);  //设置富文本编辑器不可编辑

      } else {
        event.enable(false);  //设置富文本编辑器不可编辑

      }
    },
    uploadSuccess(res) {
      let quill = this.$refs.myQuillEditor.quill;
      let length = quill.getSelection().index;
      // 插入图片，第三个参数为服务器返回的图片链接地址
      quill.insertEmbed(length, "image", res.data.url);
      // 调整光标到最后
      quill.setSelection(length + 1);
      return false;
    },
    uploadError(err) {
      this.$message({
        offset: 70,
        type: "error",
        message: err.message,
      });
    },
  },
};
</script>

<style></style>