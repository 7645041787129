export default {
  weeks: [
    {
      weekNo: "1",
      name: "星期一",
    },
    {
      weekNo: "2",
      name: "星期二",
    },
    {
      weekNo: "3",
      name: "星期三",
    },
    {
      weekNo: "4",
      name: "星期四",
    },
    {
      weekNo: "5",
      name: "星期五",
    },
    {
      weekNo: "6",
      name: "星期六",
    },
    {
      weekNo: "7",
      name: "星期日",
    },
    {
      weekNo: "8",
      name: "调休日",
    },
    {
      weekNo: "0",
      name: "节假日",
    },
  ],
  //企业排行榜状态
  LeaderboardStatusOption: [
    {
      text: "待揭榜",
      value: "undeclared",
      color: "#D0AF15"
    },
    {
      text: "已揭榜",
      value: "listed",
      color: "#80DE50"
    },
  ],
  //工单状态
  ReleaseStatusOption: [
    {
      text: "已揭榜",
      value: "unveil",
      color: "#3fb1f2"
    },
    {
      text: "意向协议",
      value: "agreement",
      color: "#FEC900"
    },
    {
      text: "正式合同",
      value: "contract",
      color: "#86CA00"
    },
    {
      text: "完成",
      value: "complete",
      color: "#0AB013"
    },
  ],
   //记录状态
   RecordStatusOption: [
    {
      text: "合同延期",
      value: "contract_extension",
      color: "#9B6247"
    },
    {
      text: "意向协议",
      value: "agreement",
      color: "#FEC900"
    },
    {
      text: "正式合同",
      value: "contract",
      color: "#86CA00"
    },
  ],
  //审核状态
  AuditStatusOption: [
    {
      text: "草稿",
      value: "draft",
      color: "#666666"
    },
    {
      text: "已提交",
      value: "submitted",
      color: "#3fb1f2"
    },
    {
      text: "审核通过",
      value: "approved",
      color: "#217346"
    },
    {
      text: "审核驳回",
      value: "reject",
      color: "#fd6666"
    }
  ],

};
