<template>
  <div
    class="base-container"
    element-loading-text="加载或处理中,请稍候..."
    element-loading-background="rgba(0, 0, 0, 0.8)"
    v-loading="isLoading"
  >
    <slot></slot>
    <artisan-msg-box ref="msg"></artisan-msg-box>
  </div>
</template>

<script>
export default {
  name: "BaseContainer",
  props: {
    isLoading: Boolean,
  },
  methods: {
    alert(option) {
     return this.$refs.msg.alert(option);
    },
    confirm(option) {
      return this.$refs.msg.confirm(option);
    },
    prompt(option) {
      return this.$refs.msg.prompt(option);
    },
  },
};
</script>

<style>
</style>