const echarts = {
	namespaced: true,
	state: {
		// 大屏首页地图选中区域
		indexMapCurrentArea: '咸宁市',
	},
	actions: {},
	mutations: {
		setIndexMapCurrentArea(state, areaName) {
			state.indexMapCurrentArea = areaName;
		},
	},
};
export default echarts;
