<template>
  <el-checkbox-group v-model="checkboxs" @change="change">
    <el-checkbox v-for="dic in dicItems" :key="dic.value" :label="dic.value">
      {{ dic.label }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
import { loadEnum } from "@/api/sys/dicType";
export default {
  model: {
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    column: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value() {
      this.checkboxs = this.value ? this.value.split(",") : [];
    },
  },
  data() {
    return {
      checkboxs: this.value ? this.value.split(",") : [],
      dicItems: [],
    };
  },
  created() {
   if (this.column.dicData && this.column.dicData.length) {
      this.dicItems = this.column.dicData;
    } else {
      if (this.column.dicKey) {
        loadEnum(this.column.dicKey).then((res) => {
          this.dicItems = res.data.data;
        }).catch(() => {});
      }
    }
  },
  methods: {
    change() {
      if (this.column.change && typeof this.column.change == "function") {
        this.column.change(this.checkboxs.join(","), this.column);
      }
      this.$emit("change", this.checkboxs.join(","), this.column);
    },
  },
};
</script>

<style>
</style>