import constant from "@/config/constant";
import moment from "moment";
/**
 * 判断是否为空
 */
export function isNullOrEmpty(val) {
  if (typeof val == "boolean") {
    return false;
  }
  if (typeof val == "number") {
    return false;
  }
  if (val instanceof Array) {
    if (val.length == 0) return true;
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === "{}") return true;
  } else {
    if (
      val == "null" ||
      val == null ||
      val == "undefined" ||
      val == undefined ||
      val == ""
    )
      return true;
    return false;
  }
  return false;
}
export function vaildData(val, def) {
  return !isNullOrEmpty(val) ? val : def;
}
export function getQueryObj(url) {
  let o = {};
  let params = url.split("?")[1];
  if (params) {
    let temp = params.split("&");
    for (let i = 0; i < temp.length; i++) {
      let t = temp[i].split("=");
      o[t[0]] = t[1];
    }
  }
  return o;
}
export function isPhoto(url) {
  return /\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/.test(url);
}
export function isPwdRight(pwd) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{10,20}/.test(pwd);
}
export function formatAmount(value) {
  if (!isNaN(value)) {
    let arr = parseFloat(value).toFixed(2).split(".");
    let sInt = arr[0].replace(/\d(?=(\d{3})+$)/g, "$&,");
    return `${sInt}.${arr[1]}`;
  }
  return value;
}
/**
 * 构造树形数据
 * @param {*} arr
 * @param {*} config
 * @returns
 */
export function buildTree(arr, props) {
  const config = props || {};
  let parentField = config.parentField || "parentId";
  let idField = config.idField || "id";
  let rootVal = config.rootVal || "";
  let tree = [];
  if (arr && arr.length > 0) {
    tree = arr.filter(
      (c) => c[parentField] == rootVal || isRootId(c[parentField])
    );
    tree.forEach((c) => {
      treeChildren(c, arr, idField, parentField);
    });
  }
  return tree;
}

function isRootId(val) {
  if (typeof val == "number" && val == 0) {
    return true;
  } else {
    if (
      val == "null" ||
      val == null ||
      val == "undefined" ||
      val == undefined ||
      val == "" ||
      val == "0"
    )
      return true;
  }
  return false;
}

function treeChildren(item, list, idField, parentField) {
  let children = list.filter((c) => c[parentField] == item[idField]);
  if (children && children.length > 0) {
    item.children = children;
    item.children.forEach((c) => treeChildren(c, list, idField, parentField));
  }
}

export function fmoney(value) {
  if (!isNaN(value)) {
    let arr = parseFloat(value).toFixed(2).split(".");
    let sInt = arr[0].replace(/\d(?=(\d{3})+$)/g, "$&,");
    return `${sInt}.${arr[1]}`;
  }
  return value;
}
/**
 * 邮箱
 * @param {*} s
 */
export function isEmail(s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(
    s
  );
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile(s) {
  return /^1[0-9]{10}$/.test(s);
}
export function deepClone(target) {
  // 定义一个变量
  let result;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === "object") {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = []; // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]));
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if (target === null) {
      result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (let i in target) {
        result[i] = deepClone(target[i]);
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target;
  }
  // 返回最终结果
  return result;
}

export function getWeekLabel(i) {
  let week = constant.weeks.find((c) => c.weekNo == i);
  return week ? week.name : "";
}
export function getBelongTypeLabel(type) {
  let label = "";
  switch (type) {
    case "hos":
      label = "院";
      break;
    case "dept":
      label = "科";
      break;
  }
  return label;
}
export function getClinicTypeLabel(type) {
  let label = "不限";
  switch (type) {
    case "outpatient":
      label = "门诊";
      break;
    case "inpatient":
      label = "住院";
      break;
  }
  return label;
}
export function pointToNum(point) {
  let arr = point.split(":");
  let num = parseInt(arr[0]);
  if (arr[1] != "00") {
    num += 0.5;
  }
  return num;
}
export function calcAge(birthday) {
  if (birthday) {
    return moment().year() - moment(birthday).year();
  } else {
    return "";
  }
}

// 数字千分位转换
export function formatNumberToThousands(number) {
  let n = number;
  let r = '';
  let temp = '';
  let mod;
  do {
    // 求模的值， 用于获取高三位，这里可能有小数
    mod = n % 1000;
    // 值是不是大于1，是继续的条件
    n = n / 1000;
    // 高三位
    temp = ~~mod;
    // 1.填充: n > 1 循环未结束， 就要填充为比如 1 => 001
    // 不然temp = ~~mod的时候, 1 001， 就会变成 "11"
    // 2.拼接“,”
    r = (n >= 1 ? `${temp}`.padStart(3, '0') : temp) + (!!r ? ',' + r : '');
  } while (n >= 1);
  const strNumber = number + '';
  let index = strNumber.indexOf('.');
  // 拼接小数部分
  if (index >= 0) {
    r += strNumber.substring(index);
  }
  return r;
}