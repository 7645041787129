import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/common.scss';
import './styles/index.scss';
import './assets/icon/iconfont.css';
import BaseContainer from './components/BaseContainer';
import ArtisanGroup from './components/ArtisanGroup';
import ArtisanDialog from './components/ArtisanDialog';
import ArtisanDrawer from './components/ArtisanDrawer';
import ArtisanMsgBox from './components/ArtisanMsgBox';
import ArtisanForm from './components/ArtisanForm/index';
import ArtisanSelect from './components/ArtisanForm/ArtisanSelect';
import ArtisanGrid from '@/components/ArtisanGrid';
import ArtisanRichText from '@/components/ArtisanRichText';
import {formatNumberToThousands} from "@/util/util";

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.component('BaseContainer', BaseContainer);
Vue.component('ArtisanGroup', ArtisanGroup);
Vue.component('ArtisanDialog', ArtisanDialog);
Vue.component('ArtisanDrawer', ArtisanDrawer);
Vue.component('ArtisanMsgBox', ArtisanMsgBox);
Vue.component('ArtisanForm', ArtisanForm);
Vue.component('ArtisanSelect', ArtisanSelect);
Vue.component('ArtisanGrid', ArtisanGrid);
Vue.component('ArtisanRichText', ArtisanRichText);

Vue.filter('formatNumberToThousands', formatNumberToThousands);

// const componentsContext = require.context('./components/question', true, /\.vue$/);
// componentsContext.keys().forEach(file_name => {
//   // 获取文件中的 default 模块
//   const componentConfig = componentsContext(file_name).default;
//   Vue.component(componentConfig.name, componentConfig);
// })

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
