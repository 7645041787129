export default [
	{
		path: '/',
		name: 'index',
		component: () => import('../pages/layout'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/pages/login'),
		meta: {
			isAuth: false,
			title: '登录',
		},
	},
	{
		path: '/bigScreen',
		name: 'bigScreen',
		component: () => import('@/views/BigScreen'),
		meta: {
			isAuth: false,
			title: '平台运行实况',
		},
	},
	{
		path: '/test',
		name: 'test',
		component: () => import('@/views/Test'),
		meta: {
			isAuth: false,
			title: '测试',
		},
	},
	{
		path: '/big/home',
		name: 'bigHome',
		component: () => import('@/bigscreen/HomeView'),
		meta: {
			isAuth: false,
			title: '大屏首页',
		},
	},
	{
		path: '/supervisory',
		name: 'supervisory',
		component: () => import('@/bigscreen/Supervisory'),
		meta: {
			isAuth: false,
			title: '查看监控',
		},
	},
	{
		path: '/pdfView',
		name: 'pdfView',
		component: () => import('@/bigscreen/PdfView'),
		meta: {
			isAuth: false,
			title: '',
		},
	},
];
