<template>
  <div>
    <el-button
      v-for="btn in showBtns"
      :key="btn.code"
      :style="{ color: btn.color }"
      :type="btn.type || 'text'"
      :plain="btn.plain || false"
      size="mini"
      :icon="btn.icon"
      @click="handleClick(btn.code)"
    >
      {{ btn.name }}
    </el-button>
    <el-dropdown v-if="btns.length > max" @command="handleClick">
      <span
        style="
          cursor: pointer;
          color: #409eff;
          font-size: 12px;
          margin-left: 12px;
        "
      >
        更多<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(btn, bi) in btns.slice(max - 1)"
          :key="bi"
          :style="{ color: btn.color || '#409EFF' }"
          :icon="btn.icon"
          :command="btn.code"
          >{{ btn.name }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  props: {
    btns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    max: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    showBtns() {
      return this.btns.length > this.max ? this.btns.slice(0, this.max - 1) : this.btns;
    },
  },
  methods: {
    handleClick(code) {
      const btn = this.btns.find((c) => c.code == code);
      this.$emit("click", btn);
    },
  },
};
</script>

<style></style>
