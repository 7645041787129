import request from "@/router/axios"
export function getMeta(metaKey) {
    return request({
        url: `/api/sys/metaGrid/getMetaByCode`,
        method: 'get',
        params: {
            metaKey
        }
    })
}
export const list = (baseUrl, query) => {
    return request({
        url: `/api/${baseUrl}/list`,
        method: 'post',
        data: query
    })
}
export const page = (baseUrl, query) => {
    return request({
        url: `/api/${baseUrl}/page`,
        method: 'post',
        data: query
    })
}
export const detail = (baseUrl, params) => {
    return request({
        url: `/api/${baseUrl}/detail`,
        method: 'get',
        params: {
            ...params
        }
    })
}
export const add = (baseUrl, data) => {
    return request({
        url: `/api/${baseUrl}/add`,
        method: 'post',
        data: data
    })
}
export const edit = (baseUrl, data) => {
    return request({
        url: `/api/${baseUrl}/edit`,
        method: 'post',
        data: data
    })
}
export const remove = (baseUrl, ids) => {
    return request({
        url: `/api/${baseUrl}/del`,
        method: 'post',
        params: {
            ids
        }
    })
}
export const loadEnum = (key) => {
    return request({
        url: `/api/enum/list`,
        method: 'get',
        params: {
            key
        }
    })
}