<template>
  <el-input-number
    v-model="num"
    controls-position="right"
    style="width: 100%"
    :placeholder="placeholder"
    :step="1"
    :step-strictly="column.dataType=='integer'"
    @input="input"
  >
  </el-input-number>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    column: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value() {
      this.num = isNaN(this.value) ? parseFloat(this.value) : this.value;
    },
  },
  data() {
    let num = 0;
    if (this.value) {
      num = isNaN(this.value) ? parseFloat(this.value) : this.value;
    }
    return {
      num,
    };
  },
  computed:{
    placeholder() {
      return this.column.placeholder || "请输入" + this.column.name;
    },
  },
  methods: {
    input() {
      if (this.column.input && typeof this.column.input == "function") {
        this.column.input(this.num, this.column);
      }
      this.$emit("input", this.num);
    },
  },
};
</script>

<style>
</style>