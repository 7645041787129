<template>
  <el-time-select
    :placeholder="placeholder"
    v-model="time"
    :picker-options="{
      start: '00:00',
      step: '00:30',
      end: '24:00',
    }"
    @change="change"
  >
  </el-time-select>
</template>

<script>
export default {
  model: {
    event: "change",
  },
  props: {
    value: String,
    column: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value() {
      this.time = this.value;
    },
  },
  data() {
    return {
      time: this.value,
    };
  },
  computed: {
    placeholder() {
      return this.column.placeholder || "请输入" + this.column.name;
    },
  },
  methods: {
    change() {
      if (this.column.change && typeof this.column.change == "function") {
        this.column.change(this.time, this.column);
      }
      this.$emit("change", this.time, this.column);
    },
  },
};
</script>

<style>
</style>