<template>
  <el-input
    type="textarea"
    :rows="3"
    v-model="text"
    :placeholder="placeholder"
    @input="input"
  ></el-input>
</template>

<script>
export default {
  props: {
    value: String,
    column: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value() {
      this.text = this.value;
    },
  },
  data() {
    return {
      text: this.value,
    };
  },
  computed:{
    placeholder() {
      return this.column.placeholder || "请输入" + this.column.name;
    },
  },
  methods: {
    input() {
      if (this.column.input && typeof this.column.input == "function") {
        this.column.input(this.text, this.column);
      }
      this.$emit("input", this.text, this.column);
    },
  },
};
</script>

<style>
</style>