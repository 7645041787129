<template>
  <artisan-group title="检索条件">
    <template #header>
      <el-row type="flex" justify="space-between" align="middle">
        <span>检索条件</span>
        <!-- <span
          v-if="columns.length > 3"
          class="clickable color-primary"
          @click="isExpand = !isExpand"
        >
          <i v-if="isExpand" class="el-icon-arrow-right"></i>
          <i v-else class="el-icon-arrow-down"></i>
        </span> -->
      </el-row>
    </template>
    <el-form ref="gridSearchForm" size="mini" :model="formData" @keyup.enter.native="search" class="grid-search">
      <el-row type="flex">
        <div style="flex: 1">
          <el-row :gutter="20">
            <el-col v-if="$slots.header" :span="span" :style="{ height }">
              <slot name="header"></slot>
            </el-col>
            <el-col :span="span" :style="{ height }" v-for="(column, index) in columnsOfSearch" :key="index">
              <el-form-item label-width="110px" :prop="column.code" :label="column.name">
                <slot v-if="column.searchSlot" :column="column" :data="formData" :name="column.code + 'Search'"></slot>
                <div v-else>
                  <template v-if="column.dataType == 'bool'">
                    <el-select clearable :filterable="true" style="width: 100%" v-model="formData[column.code]">
                      <el-option value="" label="全部">全部</el-option>
                      <el-option :value="true" label="是"></el-option>
                      <el-option :value="false" label="否"></el-option>
                    </el-select>
                  </template>
                  <template v-else-if="column.inputType == 'radio'">
                    <el-radio-group v-model="formData[column.code]">
                      <el-radio>全部</el-radio>
                      <template v-for="(dic, dicIndex) in column.dicData">
                        <el-radio :key="dicIndex" :label="dic.value">{{
                          dic.label
                          }}</el-radio>
                      </template>
                    </el-radio-group>
                  </template>
                  <template v-else-if="column.inputType == 'checkbox'">
                    <el-checkbox-group v-model="formData[column.code]">
                      <el-checkbox :key="dicIndex" :label="dic.value">{{
                        dic.label
                        }}</el-checkbox>
                    </el-checkbox-group>
                  </template>
                  <el-input-number v-else-if="
                      column.dataType == 'number' && column.inputType == 'input'
                    " clearable v-model="formData[column.code]" controls-position="right"
                    style="width: 100%"></el-input-number>
                  <template v-else-if="column.inputType == 'date'">
                    <el-date-picker style="width: 100%" v-model="formData[column.code]" type="daterange"
                      format="yyyy-MM-dd" :default-value="[new Date(), new Date()]" value-format="yyyy-MM-dd"
                      placeholder="选择日期"></el-date-picker>
                  </template>
                  <template v-else-if="column.inputType == 'localdate'">
                    <el-date-picker style="width: 100%" v-model="formData[column.code]" type="date"
                      format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                  </template>
                  <template v-else-if="column.inputType == 'dateyear'">
                    <el-date-picker style="width: 100%" v-model="formData[column.code]" type="year" format="yyyy 年"
                      value-format="yyyy" placeholder="选择年"></el-date-picker>
                  </template>
                  <template v-else-if="column.inputType == 'select'">
                    <el-select clearable :filterable="true" style="width: 100%" v-model="formData[column.code]">
                      <el-option v-for="dic in column.dicData" :key="dic.value" :label="dic.label"
                        :value="dic.value"></el-option>
                    </el-select>
                  </template>
                  <template v-else-if="column.inputType == 'mutiselect'">
                    <el-select clearable style="width: 100%" v-model="formData[column.code]" multiple>
                      <el-option v-for="dic in column.dicData" :key="dic.value" :label="dic.label"
                        :value="dic.value"></el-option>
                    </el-select>
                  </template>
                  <template v-else-if="column.inputType == 'treeselect'">
                    <el-cascader style="width: 100%" v-model="formData[column.code]" :options="dicItems" :props="{
                        checkStrictly: false,
                        expandTrigger: 'hover',
                        emitPath: false,
                      }" clearable ></el-cascader>
                  </template>
                  <template v-else>
                    <el-input v-model="formData[column.code]" clearable></el-input>
                  </template>
                </div>
              </el-form-item>
            </el-col>

            <el-col v-if="$slots.footer" :span="span" :style="{ height }">
              <slot name="footer"></slot>
            </el-col>
          </el-row>
        </div>
        <div style="margin-left: 10px">
          <el-button size="mini" type="primary" @click="search" icon="el-icon-search" class="margin-h5">
            查询
          </el-button>
          <el-button class="margin-h5" size="mini" @click="reset" icon="el-icon-refresh-left">
            重置
          </el-button>
        </div>
      </el-row>
    </el-form>
  </artisan-group>
</template>

<script>
  import { buildTree } from "@/util/util";
  import { loadEnum } from "@/api/sys/dicType";
  export default {
    props: {
      value: {
        type: Object,
        default: () => {
          return {};
        },
      },
      span: {
        type: Number,
        default: 12,
      },
      height: {
        type: String,
        default: "40px",
      },
      columns: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    data() {
      let formData = {};
      this.columns.forEach((column) => {
        formData[column.code] = "";
      });
      return {
        formData,
        dicItems: [],
        isExpand: true,
      };
    },
    computed: {
      columnsOfSearch() {
        if (this.isExpand) {
          return this.columns.slice(0, 3);
        } else {
          return this.columns;
        }
      },
    },
    created() {
      this.columns.forEach((column) => {
        if (column.inputType == "treeselect") {
          console.log(column, "column:");
          console.log(column.dicData, "dicData:");
          console.log(column.dicData.lengt, "dicDatalengt:");
          if (column.dicData && column.dicData.length) {
            this.dicItems = this.dicItems = buildTree(column.dicData, {
              idField: "value",
            });
            console.log(this.dicItems, "dicItems");
          }
          else {
            console.log(column.dicKey, "dicKey:");
            if (column.dicKey) {
              loadEnum(column.dicKey).then((res) => {
                this.dicItems = buildTree(res.data.data, {
                  idField: "value",
                });
              }).catch(() => { });
            }
          }
        }
      });
    },
    watch: {
      formData: {
        handler() {
          this.$emit("input", this.formData);
        },
        deep: true,
      },
    },
    methods: {
      validate(func) {
        this.$refs.gridSearchForm.validate(func);
      },
      reset() {
        this.$refs.gridSearchForm.resetFields();
        let formData = {};
        this.columns.forEach((column) => {
          formData[column.code] = "";
        });
        Object.assign(formData, this.value);
        this.formData = formData;
        this.$emit("search", this.formData);
      },
      search() {
        this.$emit("search", this.formData);
      },
    },
  };
</script>

<style scoped>
  .grid-search {
    padding-top: 10px;
    position: relative;
  }

  .more-search {
    text-align: center;
    color: #409eff;
    border-radius: 5px;
    padding: 3px;
  }
</style>