import Vue from 'vue';
import Vuex from 'vuex';
import tabs from './modules/tabs';
import user from './modules/user';
import theme from './modules/theme';
import tenant from './modules/tenant';
import getters from './getters';
import echarts from './modules/echarts';
Vue.use(Vuex);
const index = {
	state: {
		loginRedirect: '',
	},
	mutations: {
		setLoginRedirect(state, from) {
			state.loginRedirect = from;
		},
	},
	getters: {
		getLoginRedirect(state) {
			return state.loginRedirect;
		},
	},
};
const store = new Vuex.Store({
	modules: {
		index,
		tabs,
		user,
		theme,
		tenant,
		echarts,
	},
	getters,
});
export default store;
