<template>
  <div class="artisan-group" :class="{ 'border-gray': border }">
    <div :class="flag ? 'group-header' : 'group-title'">
      <div style="flex: 1">
        <slot name="header">{{ title }}</slot>
      </div>
      <span
        class="clickable margin-h7 color-primary"
        style="font-weight: normal"
        @click="isShow = !isShow"
      >
        {{ arrowText }}
        <i
          v-if="arrow"
          class="el-icon-arrow-right"
          :class="{ 'is-active': isShow }"
        ></i>
      </span>
    </div>
    <transition name="el-zoom-in-top">
      <div class="group-main" v-show="isShow">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    arrowText: {
      type: String,
      default: "",
    },
    flag: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShow: !this.arrow,
    };
  },
};
</script>
<style scoped>
.artisan-group {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.group-title {
  line-height: normal;
  font-weight: 300;
  padding: 5px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeef5;
}
.group-header {
  line-height: normal;
  font-weight: 600;
  padding: 5px 0;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ebeef5;
}
.group-header::before {
  content: "";
  margin: 0 5px;
  width: 3px;
  height: 16px;
  background-color: #409eff;
}
.group-main {
  padding: 5px 5px;
  flex: 1;
  overflow: auto;
}
.is-active {
  transform: rotate(90deg);
}
</style>
