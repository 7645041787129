<template>
  <el-switch v-model="switchVal" @change="change"> </el-switch>
</template>

<script>
export default {
  model: {
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    column: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value() {
      this.switchVal = this.value;
    },
  },
  data() {
    return {
      switchVal: this.value,
    };
  },
  methods: {
    change() {
      if (this.column.change && typeof this.column.change == "function") {
        this.column.change(this.switchVal, this.column);
      }
      this.$emit("change", this.switchVal, this.column);
    },
  },
};
</script>

<style>
</style>