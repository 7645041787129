import {
    userInfo,
    userMenus
} from "@/api/sys/user"
import {
    buildTree
} from "@/util/util"
const user = {
    state: {
        user: {
            roles: []
        },
        menus: [],
        btns: {}
    },
    actions: {
        getUser({
            state
        }) {
            return new Promise((resolve, reject) => {
                userInfo().then(res => {
                    state.user = res.data.data;
                    resolve(res);
                }).catch(error => {
                    reject(error);
                })
            });

        },
        getUserMenus({
            state
        }) {
            return new Promise((resolve, reject) => {
                userMenus().then(res => {
                    const data = res.data.data;
                    const menus = data.filter(f => f.menuType == "menu");
                    const btns = data.filter(f => f.menuType != "menu");
                    state.menus = buildTree(menus);
                    btns.forEach(btn => {
                        state.btns[btn.code] = true;
                    });
                    resolve(res);
                }).catch(error => {
                    reject(error);
                })
            });

        }
    },

}
export default user