import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import axios from 'axios';
import router from '@/router';
import Config from "@/config"
import {
    Message
} from 'element-ui';
import {
    getToken
} from "@/util/auth";
import {
    isNullOrEmpty
} from "@/util/util";
const instance = axios.create();
instance.defaults.timeout = 10000;
//返回其他状态吗
instance.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
instance.defaults.withCredentials = true;
// NProgress Configuration
NProgress.configure({
    showSpinner: false
});
//HTTPrequest拦截
instance.interceptors.request.use(config => {
    NProgress.start();
    const meta = config.meta || {};
    const isAuth = meta.isAuth == undefined || meta.isAuth;
    let token = getToken();
    //让每个请求携带token
    if (!isNullOrEmpty(token) && isAuth) {
        config.headers["AppAuth"] = Config.appAuth;
        config.headers["Authorization"] = 'bearer ' + token
    } else {
        if (isAuth) {
            router.push({
                path: '/login'
            }).catch(()=>{});
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});
//HTTPresponse拦截
instance.interceptors.response.use(res => {
    NProgress.done();
    if (res.status == 200) {
        if (!res.data.success) {
            Message({
                offset: 70,
                type: 'error',
                message: res.data.msg
            });
            return Promise.reject(new Error(res.data.msg))
        }
        return res;
    } else if (res.status === 401) {
        //如果是401则跳转到登录页面
        router.push({
            path: '/login'
        }).catch(()=>{});
        return Promise.reject(new Error('未认证'))
    } else if (res.status === 403) {
        //如果是401则跳转到登录页面
        Message({
            offset: 70,
            type: 'error',
            message: "此操作未授权！",
        });
        return Promise.reject(new Error('此操作未授权！'))
    } else {
        // 如果请求为非200否者默认统一处理
        let msg = `请求错误${res.status}`;
        Message({
            offset: 70,
            type: 'error',
            message: msg
        });
        return Promise.reject(new Error(msg))
    }
}, error => {
    NProgress.done();
    return Promise.reject(new Error(error));
})

export default instance;