const tabs = {
    state: {
        theme:{
            navStyle: "toptree",//top,left,right,topleft,topright,leftleft,rightright,toptree,lefttree,righttree
            name: "default"
        }
    },
    actions: {},
    mutations: {
        setNavStype: (state, navStyle) => {
            state.theme.navStyle = navStyle;
        },
        setThemeName: (state, name) => {
            state.theme.name = name;
        },
    }
}
export default tabs