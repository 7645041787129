import request from "@/router/axios"
export const get = (id) => {
    return request({
        url: `/api/sys/metaGrid/get`,
        method: 'get',
        params: {
            id
        }
    })
}
export function save(data) {
    return request({
        url: `/api/sys/metaGrid/save`,
        method: 'post',
        data
    })
}
export const getColumns = (metaId) => {
    return request({
        url: `/api/sys/metaColumn/getColumns`,
        method: 'get',
        params: {
            metaId
        }
    })
}
export function getMeta(metaKey) {
    return request({
        url: `/api/sys/metaGrid/getMetaByCode`,
        method: 'get',
        params: {
            metaKey
        }
    })
}
export const autoColumns = (metaId, entityName) => {
    return request({
        url: `/api/sys/metaColumn/autoColumns`,
        method: 'post',
        params: {
            metaId,
            entityName
        }
    })
}
