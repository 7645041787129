<template>
  <el-select
    style="width: 100%"
    v-model="selVal"
    multiple
    :placeholder="placeholder"
    @change="change"
  >
    <el-option
      v-for="item in dicItems"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    ></el-option>
  </el-select>
</template>

<script>
import { loadEnum } from "@/api/sys/dicType";
export default {
  model: {
    event: "change",
  },
  props: {
    value: String,
    column: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value() {
      this.selVal = this.value ? this.value.split(",") : [];
    },
  },
  computed:{
    placeholder() {
      return this.column.placeholder || "请输入" + this.column.name;
    },
  },
  data() {
    return {
      selVal: this.value ? this.value.split(",") : [],
      dicItems: [],
    };
  },
  created() {
    if (this.column.dicData && this.column.dicData.length) {
      this.dicItems = this.column.dicData;
    } else {
      if (this.column.dicKey) {
        loadEnum(this.column.dicKey).then((res) => {
          this.dicItems = res.data.data;
        }).catch(() => {});
      }
    }
  },
  methods: {
    change() {
      if (this.column.change && typeof this.column.change == "function") {
        this.column.change(this.selVal.join(","), this.column);
      }
      this.$emit("change", this.selVal.join(","), this.column);
    },
  },
};
</script>

<style>
</style>