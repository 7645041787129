<template>
  <transition name="el-zoom-in-center">
    <div class="artisan-warpper" v-show="visible">
      <div class="artisan-msg-box" :style="msgboxStyle">
        <div
          class="artisan-msg-box-header"
          :style="{ textAlign: option.titleAlign }"
        >
          <b v-text="option.title"></b>
        </div>
        <div class="artisan-msg-box-main">
          <div class="margin-v12" v-html="option.msg"></div>
          <div class="margin-v12" v-if="isPrompt">
            <el-input
              size="mini"
              v-model="val"
              :placeholder="option.msg"
            ></el-input>
          </div>
          <div class="margin-v12 color-red" v-html="option.remark"></div>
        </div>
        <div
          class="artisan-msg-box-footer"
          :style="{ textAlign: option.footAlign }"
        >
          <el-button
            type="danger"
            icon="el-icon-close"
            size="mini"
            v-if="type != 'alert'"
            @click="cancel"
          >
            {{ option.cancelText }}
          </el-button>
          <el-button icon="el-icon-check" type="primary" size="mini" @click="toConfirm">
            {{ option.confirmText }}
          </el-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      option: {
        title: "提示",
        msg: "",
        titleAlign: "left",
        footAlign: "center",
        width: "",
        height: "",
        cancelText: "取消",
        confirmText: "确认",
        remark: "",
        value: "",
      },
      val: "",
      type: "alert",
      visible: false,
      resolve: "",
      reject: "",
    };
  },
  computed: {
    msgboxStyle() {
      let style = {};
      if (this.option.width) style.width = this.option.width;
      if (this.option.height) style.height = this.option.height;
      return style;
    },
    isPrompt() {
      return this.type == "prompt";
    },
  },
  methods: {
    alert(option) {
      this.type = "alert";
      this.resolve = undefined;
      this.reject = undefined;
      Object.assign(this.option, option);
      this.visible = true;
    },
    confirm(option) {
      return new Promise((resolve, reject) => {
        this.type = "confirm";
        this.resolve = resolve;
        this.reject = reject;
        Object.assign(this.option, option);
        this.visible = true;
      });
    },
    prompt(option) {
      return new Promise((resolve, reject) => {
        this.type = "prompt";
        this.val = "";
        this.resolve = resolve;
        this.reject = reject;
        Object.assign(this.option, option);
        this.val = this.option.value;
        this.visible = true;
      });
    },
    cancel() {
      this.visible = false;
      // if (this.reject) this.reject(this.val);
    },
    toConfirm() {
      if (this.type == "prompt") {
        if (this.val) {
          this.visible = false;
          this.resolve(this.val);
        }
      } else {
        this.visible = false;
        if (this.resolve) this.resolve(this.val);
      }
    },
  },
};
</script>

<style></style>
