import { getQueryObj } from "@/util/util";
const tabs = {
  state: {
    tabList: [],
    homeTab: {},
  },
  actions: {},
  mutations: {
    setHomeTab: (state, home) => {
      if (state.homeTab.name) return;
      state.homeTab = {
        code: "",
        name: home.name,
        path: () => import(`@/views/${home.url}.vue`),
        query: getQueryObj(home.url),
        icon: home.icon || "el-icon-s-home",
        closable: false,
        isActive: true,
        key: `${home.name}-${Math.random()}`,
      };
      state.tabList.push(state.homeTab);
    },
    addTab: (state, tab) => {
      let extTab = state.tabList.find((f) => f.name == tab.name);
      if (!tab.url) return;
      let hasHttp = tab.url.indexOf("http") == 0;
      let query = {};
      if (hasHttp) {
        query = { url: tab.url };
        tab.url = "Frame";
      } else {
        query = getQueryObj(tab.url);
      }

      let activeTab = state.tabList.find((f) => f.isActive);
      if (extTab) {
        if (activeTab) activeTab.isActive = false;
        extTab.query = query;
        extTab.isActive = true;
      } else {
        if (activeTab) activeTab.isActive = false;
        let url = tab.url.split("?")[0];
        let addTab = {
          code: tab.code,
          name: tab.name,
          path: () => import(`@/views/${url}.vue`),
          query,
          icon: tab.icon || "",
          closable: true,
          isActive: true,
          key: `${tab.name}-${Math.random()}`,
        };
        state.tabList.push(addTab);
      }
    },
    activeTab: (state, tabName) => {
      let activingTab = state.tabList.find((f) => f.name == tabName);
      if (activingTab) {
        let activeTab = state.tabList.find((f) => f.isActive);
        activeTab.isActive = false;
        activingTab.isActive = true;
      }
    },
    changeTab: (state, tab) => {
      let extTab = state.tabList.find((f) => f.name == tab.name);
      if (!tab.url) return;
      let query = getQueryObj(tab.url);
      if (extTab) {
        state.tabList = state.tabList.filter((item) => !item.isActive);
        extTab.query = query;
        extTab.isActive = true;
      } else {
        let activeTab = state.tabList.find((f) => f.isActive);
        if (activeTab) {
          let url = tab.url.split("?")[0];
          activeTab.name = tab.name;
          activeTab.path = () => import(`@/views/${url}.vue`);
          activeTab.query = query;
          activeTab.icon = tab.icon || "";
        }
      }
    },
    delTab: (state, tabName) => {
      let delTab = tabName
        ? state.tabList.find((f) => f.name == tabName)
        : state.tabList.find((item) => item.isActive);
      let index = state.tabList.indexOf(delTab);
      if (delTab.closable) {
        state.tabList = state.tabList.filter(
          (item) => item.name !== delTab.name
        );
        if (delTab.isActive) {
          state.tabList[index - 1].isActive = true;
        }
      }
    },
    refreshTab(state, tabName) {
      if (tabName) {
        let tab = state.tabList.find((f) => f.name == tabName);
        if (tab) tab.key = `${tab.name}-${Math.random()}`;
      } else {
        let tab = state.tabList.find((f) => f.isActive);
        if (tab) tab.key = `${tab.name}-${Math.random()}`;
      }
    },
    delAllTab: (state) => {
      state.tabList = state.tabList.filter((item) => !item.closable);
      state.homeTab.isActive = true;
    },
    delOtherTab: (state) => {
      state.tabList = state.tabList.filter(
        (item) => item.isActive || !item.closable
      );
    },
  },
};
export default tabs;
