<template>
  <transition name="el-zoom-in-center">
    <div class="artisan-warpper" v-show="visible" :style="warpperStyle">
      <div
        class="artisan-dialog"
        :element-loading-text="loadingText"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        v-loading="isLoading"
        :style="dialogStyle"
      >
        <div
          class="dialog-header"
          :style="{ textAlign: this.titleAlign }"
          @mousedown="toDrag"
        >
          <div class="text-bold dialog-header-left">
            <slot name="title">{{ title }}</slot>
          </div>
          <div>
            <el-button
              type="text"
              style="color: #fff"
              :icon="isMax ? 'el-icon-copy-document' : 'el-icon-full-screen'"
              size="mini"
              circle
              @click="isMax = !isMax"
            ></el-button>
            <el-button
              style="margin-left: 0; color: #fff"
              type="text"
              icon="el-icon-close"
              size="mini"
              circle
              @click="close"
            ></el-button>
          </div>
        </div>
        <div class="dialog-main">
          <component
            v-if="isUrl"
            :key="refreshKey"
            :is="path"
            :query="query"
          ></component>
          <slot v-else></slot>
        </div>
        <div
          class="dialog-footer"
          :style="{ textAlign: footAlign }"
          v-if="$slots.footer"
        >
          <slot name="footer"></slot>
        </div>
        <artisan-msg-box ref="msgbox"></artisan-msg-box>
      </div>
    </div>
  </transition>
</template>

<script>
import { isNullOrEmpty } from "@/util/util";
import { getQueryObj } from "@/util/util";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String],
      default: "60%",
    },
    height: {
      type: [String],
      default: "60%",
    },
    titleAlign: {
      type: String,
      default: "left",
    },
    footAlign: {
      type: String,
      default: "center",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: "加载或处理中,请稍候...",
    },
    zIndex: Number,
  },
  data() {
    return {
      refreshKey: "",
      isMax: false,
    };
  },
  computed: {
    isUrl() {
      return !isNullOrEmpty(this.url);
    },
    path() {
      if (this.isUrl) {
        let path = this.url.split("?")[0];
        return () => import(`@/views/${path}.vue`);
      }
      return "";
    },
    query() {
      return getQueryObj(this.url);
    },
    warpperStyle() {
      let style = {};
      if (this.zIndex) {
        style.zIndex = this.zIndex;
      }
      return style;
    },
    dialogStyle() {
      let style = this.isMax
        ? {
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }
        : {
            width: this.width,
            height: this.height,
            transform: "translate(-50%, -50%)",
          };
      if (this.zIndex) {
        style.zIndex = this.zIndex + 1;
      }
      return style;
    },
  },
  watch: {
    visible() {
      if (this.visible && this.url) {
        this.refreshKey = Math.random();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
      this.$emit("dialog-close");
    },
    alert(option) {
      return this.$refs.msgbox.alert(option);
    },
    confirm(option) {
      return this.$refs.msgbox.confirm(option);
    },
    prompt(option) {
      return this.$refs.msgbox.prompt(option);
    },
    toDrag(e) {
      let dragBox = e.target;
      let dialog = this.getMoveElement(dragBox);
      dragBox.style.cursor = "pointer";
      //算出鼠标相对元素的位置
      let disX = e.clientX - dialog.offsetLeft;
      let disY = e.clientY - dialog.offsetTop;
      dialog.parentElement.onmousemove = (e) => {
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        //移动当前元素
        dialog.style.left = left + "px";
        dialog.style.top = top + "px";
      };
      dragBox.onmouseup = (e) => {
        //鼠标弹起来的时候不再移动
        dialog.parentElement.onmousemove = null;
        //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
        dialog.parentElement.onmouseup = null;
        dragBox.style.cursor = "";
      };
    },
    getMoveElement(element) {
      if (
        element.className &&
        element.className.indexOf("artisan-dialog") >= 0
      ) {
        return element;
      } else {
        return this.getMoveElement(element.parentElement);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.artisan-dialog {
  position: absolute;
  max-height: 100%;
  min-height: 20%;
  max-width: 100%;
  min-width: 20%;
  top: 50%;
  left: 50%;
  background-color: #f5f7f9;
  color: #303133;
  z-index: 9002;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .dialog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    /* background: #fff; */
    background-color: #409eff;
    color: #fff;
    border-bottom: 1px solid #dcdfe6;

    .dialog-header-left {
      flex: 1;
    }
  }

  .dialog-main {
    flex: 1;
    padding: 5px 5px;
    overflow: auto;
  }

  .dialog-content {
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .dialog-footer {
    background-color: #f5f7f9;
    padding: 5px 10px;
    text-align: right;
    border-top: 1px solid #dcdfe6;
    overflow: hidden;
  }
}
</style>
