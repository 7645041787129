import Cookies from 'js-cookie';
import axios from 'axios';
import webConfig from "@/config";
const TokenKey = 'learn-access-token';
const RefreshTokenKey = 'learn-refresh-token';

export function getToken() {
    return Cookies.get(TokenKey);
}
export function setToken(token) {
    return Cookies.set(TokenKey, token, {
        expires: 6
    });
}

export function getRefreshToken() {
    return Cookies.get(RefreshTokenKey)
}

export function setRefreshToken(token) {
    return Cookies.set(RefreshTokenKey, token, {
        expires: 12
    })
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}
export function removeRefreshToken() {
    return Cookies.remove(RefreshTokenKey)
}