import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  getToken
} from '@/util/auth'
import {
  isNullOrEmpty
} from "@/util/util"
import routes from './routes'
import store from '../store'
import config from "@/config"
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {

  const meta = to.meta || {};
  if (meta && meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = config.title;
  }
  const isAuth = meta.isAuth == undefined || meta.isAuth;
  let token = getToken();
  if (isAuth) {
    if (!isNullOrEmpty(token)) {
      next();
    } else {
      store.commit("setLoginRedirect", to);
      next({
        path: "/login"
      });
    }
  } else {
    next();
  }
});
router.afterEach((to) => {

});
export default router