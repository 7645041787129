import request from "@/router/axios"
export const list = (query) => {
    return request({
        url: `/api/sys/dicType/list`,
        method: 'post',
        data: query
    })
}
export const page = (query) => {
    return request({
        url: `/api/sys/dicType/page`,
        method: 'post',
        data: query
    })
}
export const detail = (params) => {
    return request({
        url: `/api/sys/dicType/detail`,
        method: 'get',
        params: {
            ...params
        }
    })
}
export const add = (data) => {
    return request({
        url: `/api/sys/dicType/add`,
        method: 'post',
        data: data
    })
}
export const edit = (data) => {
    return request({
        url: `/api/sys/dicType/edit`,
        method: 'post',
        data: data
    })
}
export const remove = (ids) => {
    return request({
        url: `/api/sys/dicType/del`,
        method: 'post',
        params: {
            ids
        }
    })
}
export const loadEnum = (key) => {
    return request({
        url: `/api/enum/list`,
        method: 'get',
        params: {
            key
        }
    });
}
export const loadEnums = (keys) => {
    return request({
        url: `/api/enum/listByKeys`,
        method: 'get',
        params: {
            keys
        }
    });
}