<template>
  <el-table-column
    v-if="vaildColumn(column)"
    :column-key="column.code"
    :key="column.code"
    :prop="column.code"
    :label="column.name"
    filter-placement="bottom-end"
    :filters="handleFilters(column)"
    :filter-method="column.filter ? handleFiltersMethod : undefined"
    :filter-multiple="vaildData(column.filterMultiple, true)"
    :show-overflow-tooltip="column.overTooltip"
    :min-width="column.minWidth"
    :sortable="column.sortable ? 'custom' : false"
    :render-header="column.renderHeader"
    :align="align"
    :header-align="headerAlign"
    :width="column.width"
    :fixed="column.fixed"
    :class-name="column.className"
    :label-class-name="column.labelClassName"
    :formatter="column.format"
  >
    <span slot-scope="scope">
      <slot
        :row="scope.row"
        :label="handleShowLabel(scope.row, column)"
        :name="column.code"
      >
        <span
          :class="
            scope.row[column.code]
              ? 'artisan-input-checked'
              : 'artisan-input-uncheck'
          "
          v-if="column.dataType == 'bool'"
        >
          <i v-show="scope.row[column.code]" class="el-icon-check"> </i>
        </span>
        <span v-else v-text="handleShowLabel(scope.row, column)"></span>
      </slot>
    </span>
  </el-table-column>
</template>

<script>
import { isNullOrEmpty, vaildData } from "@/util/util";
export default {
  inject: ["grid"],
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    align() {
      let align = this.column.align || this.grid.tableOption.align;
      if (!align) {
        if (this.column.dataType == "string") {
          align = "left";
        } else {
          align = "center";
        }
      }
      return align;
    },
    headerAlign() {
      return (
        this.column.headerAlign || this.grid.tableOption.headerAlign || "center"
      );
    },
  },
  methods: {
    vaildData,
    vaildColumn() {
      const prop = this.column.code;
      return !isNullOrEmpty(prop) && !this.column.hidden;
    },
    //表格筛选字典
    handleFilters() {
      if (this.column.filter !== true) return undefined;
      let arr = [];
      this.column.dicData.forEach((f) => {
        arr.push({
          text: f.label,
          value: f.value,
        });
      });
      return arr;
    },
    //表格筛选逻辑
    handleFiltersMethod(value, row, column) {
      if (typeof column.filterMethod === "function") {
        return column.filterMethod(value, row, column);
      } else {
        return row[column.code] === value;
      }
    },
    handleShowLabel(row, column) {
      if (column.dicData && column.dicData.length > 0) {
        if (row[column.code]) {
          let valArr = row[column.code].toString().split(",");
          let dics = column.dicData.filter((f) => valArr.indexOf(f.value) >= 0);
          if (dics.length > 0) {
            return dics.map((c) => c.label).join(",");
          } else {
            return "";
          }
        }
      }
      return row[column.code];
    },
  },
};
</script>

<style>
</style>