import { any } from "@/api/sys/tenant";
const user = {
    state: {
        tenant: {
        },
    },
    actions: {
        getTenant({
            state
        }) {
            return new Promise((resolve, reject) => {
                any().then(res => {
                    state.tenant = res.data.data;
                    resolve(res);
                }).catch(error => {
                    reject(error);
                })
            });

        },
    },

}
export default user