<template>
  <el-input
    v-model="pwd"
    show-password
    style="width: 100%"
    :placeholder="placeholder"
    @input="input"
  ></el-input>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    column: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value() {
      this.numpwd = this.value;
    },
  },
  data() {
    return {
      pwd: this.value,
    };
  },
  computed: {
    placeholder() {
      return this.column.placeholder || "请输入" + this.column.name;
    },
  },
  methods: {
    input() {
      if (this.column.input && typeof this.column.input == "function") {
        this.column.input(this.pwd, this.column);
      }
      this.$emit("input", this.pwd, this.column);
    },
  },
};
</script>

<style>
</style>