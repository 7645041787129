<template>
  <div class="artisan-warpper" v-show="visible" @click.self="clickWarpper">
    <div
      class="artisan-drawer"
      :class="'artisan-drawer-' + direction"
      :style="drawerStyle"
      element-loading-text="加载或处理中,请稍候..."
      element-loading-background="rgba(0, 0, 0, 0.8)"
      v-loading="isLoading"
    >
      <div
        class="artisan-drawer-header"
        :style="{ textAlign: this.titleAlign }"
      >
        <div class="text-bold drawer-header-left">
          <slot name="title">{{ title }}</slot>
        </div>
        <div class="drawer-header-right">
          <el-button
            type="text"
            :icon="isMax ? 'el-icon-copy-document' : 'el-icon-full-screen'"
            size="mini"
            circle
            @click="isMax = !isMax"
          ></el-button>
          <el-button
            style="margin-left: 0"
            type="text"
            icon="el-icon-close"
            size="mini"
            circle
            @click="close"
          ></el-button>
        </div>
      </div>
      <div class="artisan-drawer-main">
        <component
          v-if="isUrl"
          :key="refreshKey"
          :is="path"
          :query="query"
        ></component>
        <slot v-else></slot>
        <artisan-msg-box ref="msgbox"></artisan-msg-box>
      </div>
      <div
        class="artisan-drawer-footer"
        :style="{ textAlign: footAlign }"
        v-if="$slots.footer"
      >
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { isNullOrEmpty } from "@/util/util";
import { getQueryObj } from "@/util/util";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: "left",
    },
    size: {
      type: [String],
      default: "60%",
    },
    titleAlign: {
      type: String,
      default: "left",
    },
    footAlign: {
      type: String,
      default: "center",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 9100,
    },
    wrapperClosable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      refreshKey: "",
      isMax: false,
    };
  },
  computed: {
    isUrl() {
      return !isNullOrEmpty(this.url);
    },
    path() {
      if (this.isUrl) {
        let path = this.url.split("?")[0];
        return () => import(`@/views/${path}.vue`);
      }
      return "";
    },
    query() {
      return getQueryObj(this.url);
    },
    wrapperClass() {
      let name = "animate__animated ";
      switch (this.direction) {
        case "top":
          name += this.visible ? "animate__fadeInDown" : "animate__fadeOutUp";
          break;
        case "right":
          name += this.visible
            ? "animate__fadeInRightBig"
            : "animate__fadeOutRight";
          break;
        case "bottom":
          name += this.visible ? "animate__fadeInUp" : "animate__fadeOutDown";
          break;
        default:
          name += this.visible ? "animate__fadeInLeft" : "animate__fadeOutLeft";
          break;
      }
      return name;
    },
    drawerStyle() {
      let style = {};
      switch (this.direction) {
        case "top":
        case "bottom":
          if (this.visible) {
            style.height = this.isMax ? "100%" : this.size;
          } else {
            style.height = "0";
          }
          break;
        default:
          if (this.visible) {
            style.width = this.isMax ? "100%" : this.size;
          } else {
            style.width = "0";
          }
          break;
      }

      if (this.zIndex) {
        style.zIndex = this.zIndex;
      }
      return style;
    },
  },
  watch: {
    visible() {
      if (this.visible && this.url) {
        this.refreshKey = Math.random();
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
      this.$emit("drawer-close");
    },
    alert(option) {
      return this.$refs.msgbox.alert(option);
    },
    confirm(option) {
      return this.$refs.msgbox.confirm(option);
    },
    prompt(option) {
      return this.$refs.msgbox.prompt(option);
    },
    clickWarpper() {
      if (this.wrapperClosable) {
        this.close();
      }
    },
  },
};
</script>

<style scoped>
.artisan-drawer {
  background-color: #fff;
  z-index: 2000;
  position: absolute;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #dcdfe6; */
  border-left: 0;
  overflow: hidden;
}
.artisan-drawer-left {
  width: 0;
  left: 0;
  top: 0;
  bottom: 0;
  transition: width 0.3s;
  -moz-transition: width 0.3s; /* Firefox 4 */
  -webkit-transition: width 0.3s; /* Safari 和 Chrome */
  -o-transition: width 0.3s; /* Opera */
}
.artisan-drawer-top {
  height: 0;
  top: 0;
  left: 0;
  right: 0;
  transition: height 0.3s;
  -moz-transition: height 0.3s; /* Firefox 4 */
  -webkit-transition: height 0.3s; /* Safari 和 Chrome */
  -o-transition: height 0.3s; /* Opera */
}
.artisan-drawer-right {
  width: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: width 0.3s;
  -moz-transition: width 0.3s; /* Firefox 4 */
  -webkit-transition: width 0.3s; /* Safari 和 Chrome */
  -o-transition: width 0.3s; /* Opera */
}
.artisan-drawer-bottom {
  height: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: height 0.3s;
  -moz-transition: height 0.3s; /* Firefox 4 */
  -webkit-transition: height 0.3s; /* Safari 和 Chrome */
  -o-transition: height 0.3s; /* Opera */
}

.artisan-drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #dcdfe6;
  background-color: #f5f7f9;
  overflow: hidden;
  flex-wrap: nowrap;
}
.drawer-header-left {
  flex: 1;
  overflow: hidden;
}
.drawer-header-right {
  overflow: hidden;
}
.artisan-drawer-main {
  flex: 1;
  overflow: hidden;
  padding: 5px;
}

.artisan-drawer-footer {
  background-color: #f5f7f9;
  padding: 5px 10px;
  border-top: 1px solid #dcdfe6;
  overflow: hidden;
}
</style>
