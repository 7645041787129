<template>
    <el-date-picker v-model="time" style="width: 100%;"  type="datetime" @change="change" :placeholder="placeholder">
    </el-date-picker>
</template>
  
<script>
export default {
    model: {
        event: "change",
    },
    props: {
        value: String,
        column: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    watch: {
        value() {
            this.time = this.value;
        },
    },
    data() {
        return {
            time: this.value,
        };
    },
    computed: {
        placeholder() {
            return this.column.placeholder || "请选择" + this.column.name;
        },
    },
    methods: {
        change() {
            if (this.column.change && typeof this.column.change == "function") {
                this.column.change(this.time, this.column);
            }
            this.$emit("change", this.time, this.column);
        },
    },
};
</script>
  
<style></style>