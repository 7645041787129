import request from "@/router/axios"

export const setUserRoles = (userIds, roleCodes) => {
    return request({
        url: `/api/sys/userInfo/setUserRoles`,
        method: 'post',
        params: {
            userIds,
            roleCodes
        }
    })
}
export const getUserRoles = (userId) => {
    return request({
        url: `/api/sys/userInfo/getUserRoles`,
        method: 'get',
        params: {
            userId
        }
    })
}
export const resetPwd = (userIds, pwd) => {
    return request({
        url: `/api/sys/userInfo/resetPwd`,
        method: 'post',
        params: {
            userIds,
            pwd
        }
    })
}
export const editPwd = (oldPwd, newPwd) => {
    return request({
        url: `/api/sys/userInfo/editPwd`,
        method: 'post',
        params: {
            oldPwd,
            newPwd
        }
    })
}
export const userInfo = () => {
    return request({
        url: `/api/sys/userInfo/userInfo`,
        method: 'get',
    })
}
export const userMenus = () => {
    return request({
        url: `/api/sys/userInfo/userMenus`,
        method: 'get',
    })
}
export const deptList = (userCode) => {
    return request({
        url: `/api/sys/userInfo/deptList`,
        method: 'get',
        params: {
            userCode
        }
    })
  }
  export const roleList = (userCode) => {
    return request({
        url: `/api/sys/userInfo/roleList`,
        method: 'get',
        params: {
            userCode
        }
    })
  }
export const get = () => {
    return request({
        url: `/api/fpi/user/get`,
        method: 'get',
    })
}

export const userList = () => {
    return request({
        url: `/api/sys/userInfo/getUserList`,
        method: 'get',
    })
}

export const getDingTalkUserAccoutInfo = (userId) => {
    return request({
        url: `/api/fpi/user/getDingTalkUserAccoutInfo`,
        method: 'get',
        params: {
            userId
        }
    })
}
export const setBankAccount = (userId, bankAccountNo,accountBank) => {
    return request({
        url: `/api/fpi/user/setDingTalkUserAccoutInfo`,
        method: 'post',
        params: {
            userId,
            bankAccountNo,
            accountBank
        }
    })
}
export const getGridman = (deptId) => {
    return request({
        url: `/api/sys/userInfo/getUsersByCode`,
        method: 'get',
        params:{
            code:"wanggeyuan",
            deptId
        }
    })
}