<template>
  <el-radio-group v-model="radio" @change="change">
    <template v-for="(dic, dicIndex) in dicItems">
      <el-radio :key="dicIndex" :label="dic.value">
        {{ dic.label }}
      </el-radio>
    </template>
  </el-radio-group>
</template>

<script>
import { loadEnum } from "@/api/sys/dicType";
export default {
  model: {
    event: "change",
  },
  props: {
    value: {
      type: [String, Boolean],
      default: "",
    },
    column: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    value() {
      this.radio = this.value;
    },
  },
  data() {
    return {
      radio: this.value,
      dicItems: [],
    };
  },
  created() {
    if (this.column.dataType == "bool") {
      this.dicItems.push({
        value: true,
        label: "是",
      });
      this.dicItems.push({
        value: false,
        label: "否",
      });
    } else {
      if (this.column.dicData && this.column.dicData.length) {
        this.dicItems = this.column.dicData;
      } else {
        if (this.column.dicKey) {
          loadEnum(this.column.dicKey).then((res) => {
            this.dicItems = res.data.data;
          }).catch(() => {});
        }
      }
    }
  },
  methods: {
    change() {
      if (this.column.change && typeof this.column.change == "function") {
        this.column.change(this.radio, this.column);
      }
      this.$emit("change", this.radio, this.column);
    },
  },
};
</script>

<style>
</style>